import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import WaveSurfer from 'wavesurfer.js';
import { keyframes, styled } from '@mui/system';
import CustomLoader from './CustomLoader';
import './App.css';

// Mock API function to simulate fetching data
const fetchGreetingData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        image: '/assets/flower.png',
        text_1: 'Қазақша тест',
        text_2: 'You are my joy, my peace, and my everything. You are my joy, my peace, and my everything.',
        audio: '/file_example_MP3_700KB.mp3',
        from: 'От Расшн текст',
      });
    }, 3000);
  });
};

// Keyframe animation for flip
const spin = keyframes`
  from {
    transform: rotateY(0deg); // Start with no rotation
  }
  to {
    transform: rotateY(-180deg); // Rotate 180 degrees in the reverse Y direction
  }
`;

const CardContainer = styled('div')({
  perspective: '1000px',
  width: '90%',
  maxWidth: '350px',
  height: '400px',
  margin: 'auto',
});

const CardWrapper = styled('div')(({ autoSpin }) => ({
  position: 'relative',
  width: '100%',
  height: '115%',
  transformStyle: 'preserve-3d',
  animation: autoSpin ? `${spin} 1.5s ease-in-out` : 'none',
  animationFillMode: 'forwards',
}));

const FrontFace = styled(Card)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  transform: 'rotateY(180deg)',
  borderRadius: '16px',
});

const BackFace = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  backgroundColor: '#f5b0c7',
  transform: 'rotateY(0deg)',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Logo = styled('img')({
  width: '80px',
  height: '80px',
});

function Card1() {
  const waveformRef = useRef(null);
  const [waveSurfer, setWaveSurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [greetingData, setGreetingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [autoSpin, setAutoSpin] = useState(false);

  useEffect(() => {
    fetchGreetingData().then((data) => {
      setGreetingData(data);
      setLoading(false);
    });

    const spinTimeout = setTimeout(() => setAutoSpin(true), 500);
    return () => clearTimeout(spinTimeout);
  }, []);

  useEffect(() => {
    if (greetingData && waveformRef.current) {
      const waveSurferInstance = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#f5b0c7',
        progressColor: '#e57399',
        barWidth:2,
        height: 40,
        responsive: true,
      });

      waveSurferInstance.load(greetingData.audio);
      setWaveSurfer(waveSurferInstance);

      return () => waveSurferInstance.destroy();
    }
  }, [greetingData]);

  const handlePlayPause = () => {
    if (waveSurfer) {
      if (isPlaying) {
        waveSurfer.pause();
      } else {
        waveSurfer.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <CardContainer>
      <CardWrapper autoSpin={autoSpin}>
        <BackFace>
          <Logo src="/assets/rose.png" alt="Logo" />
        </BackFace>
        <FrontFace
          sx={{
            width: '100%',
            height: '100%',
            boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f9f6f7',
          }}
        >
          <CardMedia
            component="img"
            height="200"
            image={greetingData.image}
            alt="Greeting Image"
          />
          <CardContent sx={{ padding: '24px', textAlign: 'left' }}>
            <Typography variant="h5" sx={{ marginBottom: '16px' }}>
              {greetingData.text_1}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                marginBottom: '16px',
                maxWidth: '100%', // Prevent text overflow
                textAlign: 'justify', // Align text for better readability
                wordBreak: 'break-word', // Handle long unbroken text
              }}
            >
              {greetingData.text_2}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
              <IconButton
                onClick={handlePlayPause}
                sx={{
                  backgroundColor: '#f5b0c7',
                  color: 'white',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  '&:hover': { backgroundColor: '#e57399' },
                }}
              >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
              <Box
                ref={waveformRef}
                sx={{
                  flexGrow: 1,
                  height: '40px',
                  borderRadius: '8px',
                  backgroundColor: '#f5e4ea',
                }}
              ></Box>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Great Vibes',
                fontSize: '22px',
                fontWeight: 400,
                fontStyle: 'italic',
                textAlign: 'right',
              }}
            >
              {greetingData.from}.
            </Typography>
          </CardContent>
        </FrontFace>
      </CardWrapper>
    </CardContainer>
  );
}

export default Card1;
