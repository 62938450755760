import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, IconButton, CircularProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SwipeableViews from 'react-swipeable-views';
import { keyframes, styled } from '@mui/system';
import WaveSurfer from 'wavesurfer.js';

const pulse = keyframes`
  0%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-5px);
    opacity: 0.7;
  }
`;

const SwipeIndicator = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${pulse} 1.5s infinite ease-in-out`,
  color: '#e57399',
  fontSize: '16px',
  fontWeight: 'bold',
  marginTop: '16px',
});

// Greeting data array with 6 items
const greetingsData = [
  { type: 'photo-audio', content: 'Made With Love', mediaPath: './assets/flower.png', audioPath: '/path/to/audio.mp3' },
  { type: 'video', content: 'Video Message', mediaPath: '/path/to/video.mp4' },
  { type: 'photo-only', content: 'A Special Moment', mediaPath: './assets/flower.png' },
  { type: 'photo-audio', content: 'Cherished Memories', mediaPath: './assets/flower.png', audioPath: '/path/to/audio2.mp3' },
  { type: 'video', content: 'Celebration Time', mediaPath: '/path/to/video2.mp4' },
  { type: 'photo-only', content: 'Captured Moments', mediaPath: './assets/flower.png' },
];

function GroupGreetings() {
  const [showDetail, setShowDetail] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const waveformRef = useRef(null);
  const [waveSurfer, setWaveSurfer] = useState(null);

  const totalGreetings = greetingsData.length;

  useEffect(() => {
    if (showDetail && greetingsData[currentIndex].type === 'photo-audio' && waveformRef.current) {
      const waveSurferInstance = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#f5b0c7',
        progressColor: '#e57399',
        barWidth: 2,
        height: 50,
        responsive: true,
      });
      waveSurferInstance.load(greetingsData[currentIndex].audioPath);
      setWaveSurfer(waveSurferInstance);

      return () => {
        waveSurferInstance.destroy();
      };
    }
  }, [showDetail, currentIndex]);

  const handlePlayPause = () => {
    if (waveSurfer) {
      if (isPlaying) {
        waveSurfer.pause();
      } else {
        waveSurfer.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSwipeChangeIndex = (index) => {
    const newIndex = (index + totalGreetings) % totalGreetings; // Circular index calculation
    setCurrentIndex(newIndex);
    setIsPlaying(false); // Stop audio when swiping to a new greeting
    if (waveSurfer) {
      waveSurfer.pause();
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: '16px', position: 'relative' }}>
      {!showDetail ? (
        <Card sx={{ width: '408px', display: 'grid', gap: '14px', gridTemplateColumns: '1fr 1fr', padding: '14px' }}>
          {greetingsData.slice(0, 4).map((greeting, idx) => (
            <CardMedia key={idx} component="img" src={greeting.mediaPath} alt={`Greeting ${idx + 1}`} sx={{ height: '164px', borderRadius: '16px' }} />
          ))}
          <Box onClick={() => setShowDetail(true)} sx={{ position: 'relative', overflow: 'hidden', cursor: 'pointer' }}>
            <CardMedia component="img" src={greetingsData[0].mediaPath} alt="All Greetings" sx={{ height: '164px', borderRadius: '16px', filter: 'blur(4px)' }} />
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}
            >
              All Greetings
            </Typography>
          </Box>
        </Card>
      ) : (
        <Box sx={{ maxWidth: '408px', width: '100%', position: 'relative' }}>
          <SwipeableViews
            index={currentIndex}
            onChangeIndex={handleSwipeChangeIndex}
            enableMouseEvents
            resistance
          >
            {greetingsData.map((greeting, index) => (
              <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', justifyContent: 'center' }}>
                <Card sx={{ borderRadius: '16px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', overflow: 'hidden', minHeight: '50vh' }}>
                  {greeting.type === 'photo-audio' && (
                    <>
                      <CardMedia component="img" height="215px" image={greeting.mediaPath} alt="Greeting" sx={{ width: '100%' }} />
                      <CardContent sx={{ padding: '24px' }}>
                        <Typography variant="h5" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>{greeting.content}</Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '16px' }}>
                          You are my joy, my peace, and my everything.
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                          <IconButton onClick={handlePlayPause} sx={{ backgroundColor: '#f5b0c7', color: 'white', width: '50px', height: '50px', borderRadius: '50%', marginRight: '8px', '&:hover': { backgroundColor: '#e57399' } }}>
                            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                          </IconButton>
                          <Box ref={waveformRef} sx={{ flex: 1, height: '50px', borderRadius: '8px', backgroundColor: '#f5e4ea' }} />
                        </Box>
                      </CardContent>
                    </>
                  )}

                  {greeting.type === 'video' && (
                    <CardMedia component="video" controls height="215px" src={greeting.mediaPath} sx={{ width: '100%' }} />
                  )}

                  {greeting.type === 'photo-only' && (
                    <CardContent sx={{ padding: '24px', textAlign: 'center' }}>
                      <CardMedia component="img" height="215px" image={greeting.mediaPath} alt="Greeting" sx={{ width: '100%' }} />
                      <Typography variant="h5" sx={{ marginTop: '16px' }}>{greeting.content}</Typography>
                    </CardContent>
                  )}
                </Card>

                {/* Swipe Indicator */}
                <SwipeIndicator>
                  <span>&#x2192;</span> {/* Right arrow symbol */}
                  <Typography variant="body2" sx={{ marginLeft: '8px' }}>Swipe</Typography>
                </SwipeIndicator>
              </Box>
            ))}
          </SwipeableViews>
        </Box>
      )}
    </Box>
  );
}

export default GroupGreetings;
