import React from 'react';
import { Box, Typography } from '@mui/material';

function CustomLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      {/* Animated GIF */}
      <img
        src="/assets/loading2.gif"
        alt="Loading animation"
        style={{
          width: '120px',
          height: '120px',
          marginBottom: '16px',
        }}
      />

      {/* Romantic Message */}
      <Typography
        variant="body1"
        sx={{
          fontSize: '1rem',
          color: '#555',
          maxWidth: '80%',
        }}
      >
        Вам прислали сердечную записку. Мы в поиске ее в нашем волшебном сундуке, и вскоре она будет вам открыта.
      </Typography>
    </Box>
  );
}

export default CustomLoader;
