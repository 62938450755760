import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CardAll from './CardAll';
import Card1 from './Card1';
import Card2 from './Card2';
import Card3 from './Card3';
import CustomLoader from './CustomLoader';
import GroupGreetings from './GroupGreetings';

// Create a theme
const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'relative' }}>
          <Routes>
            <Route path="/" element={<div>Home Page</div>} />
            <Route path="/get_card/:uuid" element={<Card3 />} /> {/* Updated Route */}
            <Route path="/api/test/loader/" element={<CustomLoader />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
