import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { keyframes, styled } from '@mui/system';
import axios from 'axios'; // Import axios for API calls
import CustomLoader from './CustomLoader'; // Import the custom loader

const spin = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-180deg);
  }
`;

const CardContainer = styled('div')(({ cardHeight }) => ({
  perspective: '1000px',
  width: '90%',
  maxWidth: '350px',
  height: `${cardHeight}px`,
  margin: 'auto',
}));

const CardWrapper = styled('div')(({ autoSpin }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  transformStyle: 'preserve-3d',
  animation: autoSpin ? `${spin} 1.5s ease-in-out` : 'none',
  animationFillMode: 'forwards',
}));

const FrontFace = styled(Card)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  transform: 'rotateY(180deg)',
  borderRadius: '16px',
});

const BackFace = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  backgroundColor: '#ffaec2',
  transform: 'rotateY(0deg)',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Logo = styled('img')({
  width: '80px',
  height: '80px',
});

function Card3() {
  const { uuid } = useParams(); // Get the UUID from the URL
  const [greetingData, setGreetingData] = useState(null); // Store fetched data
  const [loading, setLoading] = useState(true); // Loading state
  const [autoSpin, setAutoSpin] = useState(false); // Auto-spin state
  const [cardHeight, setCardHeight] = useState(330); // Default height

  useEffect(() => {
    const fetchGreetingData = async () => {
      try {
        const response = await axios.get(
          `http://195.210.47.10/api/v1/cards/get-by-uuid/${uuid}/`
        );
        const data = response.data;

        // Add a 3-second wait time
        await new Promise((resolve) => setTimeout(resolve, 3000));

        // Map API fields to local fields
        setGreetingData({
          image: '/assets/flower.png', // Replace with dynamic image if applicable
          text_1: data.title,
          text_2: data.main_text,
          from: data.from_who,
        });

        // Adjust card height based on text_2 length
        const textLength = data.main_text.length;
        if (textLength < 50) {
          setCardHeight(330);
        } else if (textLength < 110) {
          setCardHeight(400);
        } else {
          setCardHeight(430);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchGreetingData();

    const spinTimeout = setTimeout(() => setAutoSpin(true), 500);
    return () => clearTimeout(spinTimeout);
  }, [uuid]);

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <CardContainer cardHeight={cardHeight}>
      <CardWrapper autoSpin={autoSpin}>
        <BackFace>
          <Logo src="/assets/rose.png" alt="Logo" />
        </BackFace>
        <FrontFace
          sx={{
            width: '100%',
            height: '100%',
            boxShadow: '0px 40px 40px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f9f6f7',
          }}
        >
          <CardMedia
            component="img"
            height="200"
            image="/assets/flower.png"
            alt="Greeting Image"
          />
          <CardContent
            sx={{
              padding: '24px',
              textAlign: 'left',
              maxHeight: '400px',
              overflowY: 'auto',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#e57399',
                borderRadius: '4px',
              },
            }}
          >
            <Typography variant="h5" sx={{ marginBottom: '10px' }}>
              {greetingData.text_1}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                marginBottom: '10px',
                textAlign: 'justify',
                wordBreak: 'break-word',
              }}
            >
              {greetingData.text_2}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Great Vibes',
                fontSize: '22px',
                fontWeight: 400,
                fontStyle: 'italic',
                textAlign: 'right',
              }}
            >
              {greetingData.from}.
            </Typography>
          </CardContent>
        </FrontFace>
      </CardWrapper>
    </CardContainer>
  );
}

export default Card3;
